App.factory('MyEdriveTrackService', ($http, $q, $rootScope, $interval, ApiService, StorageService) =>
{
	let polling_timer;

	let fahrten_updated_ts = Date.now();
	let last_poll = Date.create();
	let tracks = null;


	// if tab is activated and last poll was Y4s ago, poll immediately
	document.addEventListener("visibilitychange", () => {if(!document.hidden && (last_poll.secondsUntil() > 4)) pollFunc();}, false);

	$rootScope.$watch('$user', (n, o) =>
	{
		if(!n && o)
		{
			tracks = null;
		}
		if(!n && polling_timer)
		{
			window.clearInterval(polling_timer);
			polling_timer = null;
		}
	});

	var pollFunc = () =>
	{
		// dont poll if no session is active
		if(!ApiService.getSessionId()) return;
		if(!$rootScope.$user) return;

		last_poll = Date.create();
		ApiService.callApi({
				method: 'gps_tracks',
				params: {
					omit_points: true
				},
				success(data, status, headers) {
					last_poll = Date.create();

					//console.log(`Last-Modified: ${Date.create(headers("Last-Modified"))}`)
					//console.log(`fahrten_updated_ts: ${Date.create(fahrten_updated_ts)}`)
					//console.log('--')
					if(Date.create(headers("Last-Modified")) > Date.create(fahrten_updated_ts))
					{
						console.log('MyEdriveTrackService: server tracks update');
						fahrten_updated_ts = Date.create(headers("Last-Modified")).getTime();
						tracks = data.data
					}
				},
				error:  angular.noop
			}
		);
	};

	return {
		startPolling() {
			if(polling_timer) return;

			polling_timer = window.setInterval(() =>
			{
				// dont poll on timer if, for whatever reason, we just triggered a poll
				if(last_poll.secondsUntil() < 5) return;
				// dont poll on timer if tab is not visible
				if(document.hidden) return;

				pollFunc();
			}, 60 * 1000);
		},

		stopPolling() {
			if(polling_timer)
			{
				window.clearInterval(polling_timer);
				polling_timer = null;
			}
		},

		getTracks() {
			if(polling_timer && tracks)
			{
				return $q(resolve => {resolve(tracks)})
			}
			else
			{
				return ApiService.callApi({
						method: 'gps_tracks',
						params: {
							omit_points: true
						},
						success(data) {
							tracks = tracks || [];
							tracks.length = 0;
							data.data.each(t =>
							{
								//if(!t.DrivingRoute) return;
								t._pristine = angular.copy(t);
								tracks.push(t);
							});
							return tracks;
						},
						error:  null
					}
				);
			}
		},

		getTrackConsumptionForCar(uuid_track, uuid_car) {
			return ApiService.callApi({
					method:  'get_track_consumption_for_car',
					params:  {uuid_car: uuid_car, uuid: uuid_track},
					success: (data) => data.data,
					error:   null
				}
			);
		},

		getUpdatedTimestamp() {
			return fahrten_updated_ts;
		},

		touchUpdatedTimestamp() {
			fahrten_updated_ts = Date.now();
			console.log(`touchUpdatedTimestamp ${Date.create(fahrten_updated_ts)}`)
		},

		removeTrack(uuid) {
			const track = tracks.find(t => t.uuid == uuid);
			const originalIndex = tracks.findIndex(t => t.uuid == uuid);

			return ApiService.callApi({
				method:     'gps_tracks',
				httpmethod: 'DELETE',
				params:     {uuid}
			}).then(() =>
			{
				// remove track from list
				tracks.remove(t => t.uuid == uuid);
				// set last modified timestmap for other controllers to watch
				this.touchUpdatedTimestamp();
			}, response =>
			{
				// undo removal
				tracks.add(track, originalIndex);
				throw response;
			});
		}
	};
});
